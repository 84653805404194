<template>
  <div>
    <div class="row my-2">
      <h1 class="col-sm-12 text-right">Usuarios</h1>
    </div>

    <Usuario :area="area" @update="obtener_area" />
  </div>
</template>

<script>
  import Usuario from './Area/Detalle/Usuario';

  import apiMiArea from '@/apps/tickets/api/miarea';

  export default {
    components: {
      Usuario
    }
    ,data() {
      return {
        area: {
          id: null,
          usuarios: [],
          etapas: [],
          requerimientos: []
        }
      }
    }
    ,mounted() {
      this.obtener_area();
    }
    ,methods: {
      async obtener_area() {
        try {
          this.area = (await apiMiArea.obtener_area()).data;
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
    }
  }
</script>